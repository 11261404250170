// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-sobre-mi-js": () => import("./../../../src/pages/sobre-mi.js" /* webpackChunkName: "component---src-pages-sobre-mi-js" */),
  "component---src-templates-blog-post-index-js": () => import("./../../../src/templates/BlogPost/index.js" /* webpackChunkName: "component---src-templates-blog-post-index-js" */),
  "component---src-templates-custom-page-index-js": () => import("./../../../src/templates/CustomPage/index.js" /* webpackChunkName: "component---src-templates-custom-page-index-js" */)
}

